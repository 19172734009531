<!--
 * @version: 1.0
 * @Date: 2023-05-04 18:48:33
 * @LastEditors: 杨慢慢
-->
<template>
  <a-form :rules="rules" :model="formState" ref="formRef">
    <a-form-item label="控件名称2" name="durationTitle">
      <a-input
        v-model:value="config.durationTitle"
        placeholder="请输入"
        :maxLength="6"
        :disabled="config?.allDisabled"
      />
    </a-form-item>
    <a-form-item label="占位符1" name="placeholder">
      <a-input
        v-model:value="config.placeholder[0]"
        placeholder="请输入"
        :maxLength="6"
        :disabled="config?.allDisabled"
      />
    </a-form-item>
    <a-form-item label="占位符2" name="placeholder">
      <a-input
        v-model:value="config.placeholder[1]"
        placeholder="请输入"
        :maxLength="6"
        :disabled="config?.allDisabled"
      />
    </a-form-item>
    <a-form-item label="日期类型" name="format">
      <a-radio-group name="radioGroup" v-model:value="config.format" :disabled="config?.allDisabled">
        <a-radio value="yyyy-MM-DD">年-月-日</a-radio>
        <br />
        <div style="height: 8px"></div>
        <a-radio value="yyyy-MM-DD HH:mm">年-月-日 时：分</a-radio>
      </a-radio-group>
    </a-form-item>
  </a-form>
</template>

<script setup>
import { defineProps, ref, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps(['config'])
const formState = props.config
const formRef = ref()

onMounted(() => {
  formRef.value.validateField(['durationTitle'], err => {
    console.log(err)
  })
})

// 控件名称失焦
const titleBlur = (rules, value) => {
  if (!value) {
    return Promise.reject('控件名称为空，请填写控件名称')
  } else {
    // console.log('77777',value);
    const formItems = store.state.process.design.formItems
    let titleList = []
    formItems.forEach(item => {
      // console.log('333333',item.title);
      if (item.controlName === 'SealInfo' || item.controlName === 'TAKE_OUT') {
        // 印章控件组
        item.props.items.forEach(el => {
          titleList.push(el.title)
        })
      }
      if (item.controlName === 'DateTimeRange') {
        titleList.push(item.props.durationTitle)
      }
      if (item.controlName === 'SealInfo') return
      titleList.push(item.title)
    })
    console.log(2222, titleList)
    // let repeact = titleList.find(el => el === value)
    const repeactList = Array.from(new Set(titleList))
    console.log(repeactList.length)
    if (repeactList.length < titleList.length) {
      return Promise.reject('控件名称不可重复')
    } else {
      return Promise.resolve()
    }
  }
}
const rules = {
  durationTitle: {
    required: true,
    trigger: 'blur',
    validator: titleBlur
  }
}
</script>

<style lang="less" scoped></style>
